import { ContextualConsent } from "@brynlabs/consent-next"
import { flatten, uniqBy } from "lodash"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import useJobOffers from "../../../hooks/useJobOffers"
import { useTranslation } from "../../../i18n/translate"
import { SoftgardenJobModel } from "../../../model/career/softgardenJobModel"
import Button from "../../button/button"
import ButtonGroup from "../../button/button-group"
import { ExpandableContainer } from "../../expandable-container/expandable-container"
import FormField from "../../form/form-field/form-field"
import Select from "../../form/select/select"
import InfoSection from "../../info-section/info-section"
import OpenVacanciesMap from "../../map/openVacanciesMap"
import Tile from "../../tile/tile"
import TileContentSpacer from "../../tile/tile-content-spacer/tile-content-spacer"
import JobOfferPreview from "../job-offer-preview/job-offer-preview"
import "./job-offers-list.sass"

const JobOffersList = ({ lang, searchParams }) => {
  /// Get all offers and filter to only show Studies & Training
  const allOffers = useJobOffers(lang)

  const [locationsFilter, setLocationsFilter] = useState([])
  const [internalLocationsFilter, setInternalLocationsFilter] = useState([])

  const catalogValueFilters = [
    {
      key: "jobCategories",
      label: "CAREER-JOB_OFFERS-JOB_CATEGORY",
      allLabel: "CAREER-JOB_OFFERS-ALL_JOB_CATEGORIES",
    },
    {
      key: "audiences",
      label: "CAREER-JOB_OFFERS-AUDIENCE",
      allLabel: "CAREER-JOB_OFFERS-ALL_AUDIENCES",
    },
    {
      key: "employmentTypes",
      label: "CAREER-JOB_OFFERS-EMPLOYMENT_TYPE",
      allLabel: "CAREER-JOB_OFFERS-ALL_EMPLOYMENT_TYPES",
    },
    {
      key: "workTimes",
      label: "CAREER-JOB_OFFERS-WORK_TIME",
      allLabel: "CAREER-JOB_OFFERS-ALL_WORK_TIMES",
    },
  ]
  const [catalogValueFilterStates, setCatalogValueFilterStates] = useState(
    catalogValueFilters.map((filter) => {
      const searchValue = searchParams.get(filter.key)

      if (searchValue) {
        const values = uniqBy(
          flatten(allOffers.map((offer) => offer[filter.key])),
          (catalogValue) => catalogValue?.id,
        )

        const index = values.findIndex((value) => {
          return (
            (value.translations.en || "").replace(/\W/g, "").toLowerCase() ===
            searchValue
          )
        })

        if (index !== -1) {
          return values[index]
        }
      }
      return undefined
    }),
  )

  // Filter out all Offers not matching the criteria
  const offers = allOffers.filter((offer) => {
    // filter out wrong location
    if (
      locationsFilter &&
      locationsFilter.length > 0 &&
      offer.locations.findIndex(
        (location) =>
          locationsFilter.findIndex(
            (locationFilter) =>
              !locationFilter || location.name.key === locationFilter.name.key,
          ) !== -1,
      ) === -1
    ) {
      return false
    }

    for (let i = 0; i < catalogValueFilters.length; i++) {
      if (
        catalogValueFilterStates[i] &&
        offer[catalogValueFilters[i].key].findIndex(
          (val) => val.id === catalogValueFilterStates[i].id,
        ) === -1
      ) {
        return false
      }
    }

    return true
  })

  const { t, i18n } = useTranslation()

  const uniqLocations = uniqBy(
    flatten(allOffers.map((offer) => offer.locations)).sort((a, b) =>
      t(a.name.key).localeCompare(t(b.name.key)),
    ),
    (location) => location?.name.key,
  )

  return (
    <div className={"_fp-col-12"}>
      <Tile titleTranslatedString={t("CAREER-JOB_OFFERS-OPEN_VACANCIES")}>
        <div className="_fp-select-grid">
          <ExpandableContainer
            showOverlay={true}
            overlayTextTranslatedString={t(
              "CAREER-JOB_OFFERS-SHOW_JOBS_ON_MAP",
            )}
          >
            <ContextualConsent contextualConsent={"google-maps"}>
              <OpenVacanciesMap
                jobOffers={offers}
                setLocations={setLocationsFilter}
                setLocationsOnClick={setInternalLocationsFilter}
                locationsFilter={internalLocationsFilter}
              ></OpenVacanciesMap>
            </ContextualConsent>
          </ExpandableContainer>
          <FormField
            titleTranslatedString={t("CAREER-JOB_OFFERS-JOB_LOCATION")}
          >
            <Select
              defaultText={
                locationsFilter &&
                locationsFilter.length > 1 &&
                locationsFilter.length < uniqLocations.length
                  ? t("CAREER-JOB_OFFERS-JOB_LOCATIONS_COUNT", {
                      count: locationsFilter.length,
                    })
                  : t("CAREER-JOB_OFFERS-ALL_JOB_LOCATIONS")
              }
              darkAppearance
              values={uniqLocations}
              value={
                locationsFilter && locationsFilter.length === 1
                  ? locationsFilter[0]
                  : undefined
              }
              onSelect={(location) => {
                setLocationsFilter([location])
                setInternalLocationsFilter(
                  location ? [location] : uniqLocations,
                )
              }}
              mapValueToString={(val) => t(val?.name.key)}
            />
          </FormField>

          {catalogValueFilters.map((filter, i) => (
            <FormField
              key={`joboffers-list-filter-${i}`}
              titleTranslatedString={t(filter.label)}
            >
              <Select
                defaultText={t(filter.allLabel)}
                darkAppearance
                values={uniqBy(
                  flatten(allOffers.map((offer) => offer[filter.key])).sort(
                    (a, b) =>
                      SoftgardenJobModel.getTranslatedCatalogValue(
                        a,
                        i18n.language,
                      ).localeCompare(
                        SoftgardenJobModel.getTranslatedCatalogValue(
                          b,
                          i18n.language,
                        ),
                      ),
                  ),
                  (catalogValue) => catalogValue?.id,
                )}
                value={catalogValueFilterStates[i]}
                onSelect={(val) => {
                  const newState = [...catalogValueFilterStates]
                  newState[i] = val
                  setCatalogValueFilterStates(newState)
                }}
                mapValueToString={(val) =>
                  SoftgardenJobModel.getTranslatedCatalogValue(
                    val,
                    i18n.language,
                  )
                }
              />
            </FormField>
          ))}
        </div>

        <TileContentSpacer />
        {offers.map((offer, index) => {
          return <JobOfferPreview key={offer.id + index} jobOffer={offer} />
        })}
        {offers.length === 0 && (
          <InfoSection
            textTranslatedString={t(
              "CAREER-JOB_OFFERS-NO_JOB_OFFER_MATCHING_CRITERIA",
            )}
          />
        )}

        <TileContentSpacer />

        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Button
            buttonExternalLinkPath={
              "https://munzing.softgarden.io/de/vacancies?-1.-jobSearch-jobSearchContainer-jobAboLink"
            }
            buttonLabel={t("CAREER-JOB_OFFERS-SUBSCRIBE_TO_JOB_OFFERS")}
            buttonSize={"large"}
            bold={true}
            buttonStyle={"green"}
            target={"_blank"}
          ></Button>

          <Button
            buttonExternalLinkPath={
              "https://munzing.softgarden.io/get-connected"
            }
            buttonLabel={t("CAREER-JOB_OFFERS-CONTACT_US")}
            buttonSize={"large"}
            bold={true}
            buttonStyle={"green-soft"}
            target={"_blank"}
          ></Button>
        </div>
      </Tile>
    </div>
  )
}

export default JobOffersList

JobOffersList.propTypes = {
  type: PropTypes.oneOf(["studiesAndTraining", "openVacancies"]),
  lang: PropTypes.string.isRequired,
}

JobOffersList.defaultProps = {
  type: "openVacancies",
}
